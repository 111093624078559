@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

:root{
  --white: #ffffff;
  --beige: #faebd7;
  --blue: #0047AB;
  --blue-dark: #00008B;
  --gold: #FFD700;
  --maroon: #881C1C;
  --peach: #F3E1C0;
  --red: #d90429;
  --teal: #008080;
  --outline: rgb(198, 198, 198);
}
body {
  background-color: #F4F3F2;
  font-family: 'Source Sans Pro', sans-serif;
}
.content {
  background-color: white;
  box-shadow: 15px 0 20px -4px grey, -15px 0 20px -2px grey;
}
.navbar {
  background-color: var(--white);
  border-bottom: 1px solid black
}
.navbar-brand {
  text-transform: uppercase;
  font-weight: 900;
}
.navbar-nav li {
  padding: 0 10px;
  border-radius: 5px;
}
.navbar-nav a {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}
.navbar-nav a:hover, .navbar-nav a:focus {
  transform: scale(1.1);
  transition: all .4s ease-out;
  color: var(--maroon);
}
section {
  padding-top: 150px;
}
#introduction {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#introduction h1 {
  font-size: 3em;
  font-weight: 900;
  padding-top: 150px;
}
#introduction h2 {
  color: var(--maroon);
  font-size: 3em;
  font-weight: 900;
  text-align: center;
}
.introLogo img{
  width: 40px;
  height: 40px;
}
.introLogo img:hover, .introLogo img:active {
  scale: 1.1;
}
.down-arrow {
  display: flex;
  position: absolute;
  bottom: 50px;
  width: 40%;
}
.down-arrow hr {
  border-top: 1px solid;
  opacity: .8;
  margin-top: 30px;
  width: 100%;
}
.down-arrow img{
  width: 60px;
  height: 60px;
}
#about img {
  max-width: 400px;
  width: 100%;
}
.title {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  padding: 30px;
}
section h1, section h2, section h3 {
  font-weight: bold;
}
.skills h3 {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  border-bottom: solid  2px var(--maroon);
  margin: 10px;
}
.skills .row {
  padding: 10px;
}
.skills .col {
  flex: 0 1;
}
.skills img {
  width: 50px;
  height: 50px;
}
/* timeline */
.cd-container {
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {   /* clearfix */
  content: "";
  display: table;
  clear: both;
}
#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#cd-timeline::before { /* vertical line */
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: var(--maroon);
}
@media(min-width: 1170px) {
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}
.cd-timeline-block {
  position: relative;
  margin: 2em 0;
  font-size: .9rem;
}
.cd-timeline-block::after {
  clear: both;
  content: "";
  display: table;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}

@media(min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}
.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.cd-timeline-img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
.cd-timeline-img.cd-picture {
  background: #75ce66;
}
.cd-timeline-img.cd-work {
  background: black;
}
.cd-timeline-img.cd-noble {
  background: #f0ca45;
}
.cd-timeline-img.cd-uni {
  background: var(--maroon);
}
.cd-timeline-img.cd-hourglass {
  background: var(--blue-dark);
}
.cd-timeline-img.cd-uni img, .cd-timeline-img.cd-work img, .cd-timeline-img.cd-hourglass img {
  filter: invert(100%);
}
@media (min-width: 1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
    /* -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden; */
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: var(--white);
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #d7e4ed;
  border: 1px solid var(--outline);
}
.cd-timeline-content::after {
  clear: both;
  content: "";
  display: table;
}
.cd-timeline-content h3 {
  border-bottom: solid 2px var(--maroon);
}
.cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  font-size: 13px;
  font-size: 0.8125rem;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  display: inline-block;
}
.cd-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}
.cd-timeline-content .cd-read-more {
  float: right;
  padding: 0.8em 1em;
  background: var(--maroon);
  color: #ffffff;
  border-radius: 0.25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
  /* background-color: #bac4cb; */
  background-color: var(--outline);
}
.cd-timeline-content .cd-date {
  float: left;
  padding: 0.8em 0;
  /* opacity: 0.7; */
}
.cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid var(--outline);
}
@media only screen and (min-width: 768px) {
  .cd-timeline-block {
    font-size: 1rem;
  }
  .cd-timeline-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: var(--outline);
  }
  .cd-timeline-content .cd-read-more {
    float: left;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: var(--outline);
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
} /* timeline end */

.projectImg img {
  border: 1px solid black;
  border-radius: 10px;
  opacity: 80%;
  transition: ease 1s;
  width: 500px;
  max-height: 200px;
}
.projectImg img:hover, #projects img:active{
  opacity: 100%;
  transform: scale(1.02);
  transition: ease 1s;
}
.projectText h4 {
  font-weight: bold;
}
.projectText a {
  text-decoration: none;
}
#contact {
  background-color: var(--white);
  border-radius: 10px;
  font-weight: bold;
}
.contact_form input::placeholder, .contact_form textarea::placeholder {
  font-weight: bold;
  opacity: 0.6;
}
footer {
  border-top: 2px solid;
  padding-top: 20px;
}
footer a {
  color: currentColor;
  padding: 20px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}
footer a:active, footer a:hover {
  color: var(--maroon);
  transition: all .1s ease-in-out;
  transform: translateY(-1px);
}
@media (max-height: 576px) {
  #introduction{
    padding-top: 0px;
  }
  .down-arrow {
    visibility: hidden;
  }
}
@media (max-height: 1000px) {
  #introduction {
    padding-top: 50px;
  }
}
@media (min-width: 768px) {
  #about p {
    font-size: 1.2rem;
  }
  .skills h2 {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  #introduction {
    font-size: 1.3rem;
  }
}